(function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
})(function () {
    var langBar = document.querySelector('.mfooter-lang'),
        close = document.createElement('div');

    close.classList.add('mfooter-close');

    langBar.addEventListener('mouseenter', function (e) {
        langBar.classList.add('active');
        document.body.appendChild(close);
    });

    langBar.addEventListener('mouseleave', function (e) {
        langBar.classList.remove('active');
        close.parentNode.removeChild(close);
    });

    langBar.addEventListener('click', function (e) {
        e.stopPropagation();
        langBar.classList.add('active');
    });

    close.addEventListener('click', function (e) {
        langBar.classList.remove('active');
        close.parentNode.removeChild(close);
    });
});